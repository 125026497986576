// sass-lint:disable-all

@font-face {
    font-family: 'suzukipro';
    src: url('../fonts/suzukiprobold-webfont.eot');
    src: url('../fonts/suzukiprobold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/suzukiprobold-webfont.woff2') format('woff2'),
         url('../fonts/suzukiprobold-webfont.woff') format('woff'),
         url('../fonts/suzukiprobold-webfont.ttf') format('truetype'),
         url('../fonts/suzukiprobold-webfont.svg#suzukiproboldregular') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'suzukipro';
    src: url('../fonts/suzukiproregular-webfont.eot');
    src: url('../fonts/suzukiproregular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/suzukiproregular-webfont.woff2') format('woff2'),
         url('../fonts/suzukiproregular-webfont.woff') format('woff'),
         url('../fonts/suzukiproregular-webfont.ttf') format('truetype'),
         url('../fonts/suzukiproregular-webfont.svg#suzukiproregularregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'suzukipro-heading';
    src: url('../fonts/suzukiproheadline-webfont.eot');
    src: url('../fonts/suzukiproheadline-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/suzukiproheadline-webfont.woff2') format('woff2'),
         url('../fonts/suzukiproheadline-webfont.woff') format('woff'),
         url('../fonts/suzukiproheadline-webfont.ttf') format('truetype'),
         url('../fonts/suzukiproheadline-webfont.svg#suzukiproheadlineregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

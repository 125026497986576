.header {
    background: $secondary-color;
    position: relative;
    z-index: 2000;

    .top-bar {
        &-logo-img {
            max-height: 64px;
        }

        &-logo-app {
            margin: rem-calc(12 20);
            width: rem-calc(116);
        }

        &-left,
        &-right {
            @include breakpoint(large down) {
                display: inline-block;
                width: 50%;
            }
        }

        &-right {
            @include breakpoint(large down) {
                text-align: right;
            }
        }

        &-center {
            display: inline-block;
            text-align: center;
            width: calc(100% - 450px);

            @include breakpoint(large down) {
                display: none;
            }
        }

        &-search {
            display: inline-block;
            padding: rem-calc(12 0);

            input {
                background: transparent;
                border-color: $white;
                border-width: 0 0 1px;
                color: $white;
                margin: 0;
                max-width: none;
                outline: none;
                width: 100%;

                &:focus {
                    background: transparent;
                    border-color: $white;
                    border-width: 0 0 1px;
                }
            }

            i {
                color: $white;
                font-size: rem-calc(26);
                margin-right: rem-calc(10);
            }
        }

        &-agenda {
            background: $primary-color;
            display: inline-block;
            float: right;
            height: rem-calc(64);
            padding: rem-calc(8 0);
            position: relative;
            text-align: center;
            width: rem-calc(64);

            @include breakpoint(large down) {
                display: none;
            }

            &.disabled {
                background: $gray-medium;
                cursor: not-allowed;
            }

            &__badge {
                position: absolute;
                right: rem-calc(5);
                top: rem-calc(5);
                z-index: 10;

                .badge-icon {
                    @include badge-icon($secondary-color, $gray-light, 22);
                }
            }

            &__icon {
                color: $gray-light;
                font-size: rem-calc(18);
                margin: rem-calc(5 0);
            }

            &__label {
                color: $gray-light;
                font-family: $header-font-family;
                font-size: rem-calc(11);
                text-transform: uppercase;
            }
        }
    }
}

.sidebar {
    &__block {
        background: $light-gray;
        margin-bottom: rem-calc(30);
        padding: rem-calc(30);

        &--orange {
            background: $primary-color;
            color: $white;
            padding: rem-calc(15 30);
        }

        &--related__item {
            margin-bottom: rem-calc(30);

            &:last-child {
                margin: 0;
            }
        }
    }
}

.main-menu {
    background: $gray-medium;
    float: left;
    height: calc(100vh - 64px);
    overflow: auto;
    width: rem-calc(128);
    z-index: 2000;

    @include breakpoint(large down) {
        //left : -128px;
        left: -168px;
        padding-right: rem-calc(40);
        position: fixed;
        top: 64px;
        transition: left 500ms ease-in;
        width: rem-calc(168);

        .mobile-menu-open & {
            left: 0;
        }
    }

    &__logout {
        a {
            border-bottom: 1px solid $gray-light;
            color: $secondary-color;
            display: block;
            font-family: $header-font-family;
            font-size: rem-calc(12);
            margin: rem-calc(0 10);
            padding: rem-calc(10 0 8);
            text-transform: uppercase;

            &:hover {
                color: $primary-color;
            }

            i {
                float: right;
                font-size: rem-calc(18);
                margin-right: 0;
                margin-top: rem-calc(-5);
            }
        }
    }

    & > ul {
        @extend .menu;
        @extend .vertical;
    }

    .item-blue {
        background: $secondary-color;
    }

    ul > li {
        &.disabled {
            opacity: .5;

            & > a,
            & > span {
                cursor: not-allowed;
            }
        }

        &.current {
            background: $light-gray;

            &.item-blue {
                & > a,
                & > span {
                    color: $secondary-color;
                }
            }
        }

        & > a,
        & > span {
            border-bottom: 1px solid $gray-light;
            cursor: pointer;
            display: block;
            font-family: $header-font-family;
            font-size: rem-calc(12);
            margin: rem-calc(0 10);
            padding: rem-calc(10 0 8);
            text-transform: uppercase;

            i {
                float: right;
                font-size: rem-calc(20);
                margin-right: 0;
                margin-top: rem-calc(-5);
            }

            &:active,
            &:focus {
                box-shadow: none;
            }
        }

        .menu-label {
            display: inline-block;
            max-width: rem-calc(75);
        }

        &.no-border {
            & > a,
            & > span {
                border: 0;
            }
        }

        &.item-blue  {
            & > a,
            & > span {
                color: $gray-light;

                i {
                    color: $gray-dark;
                }
            }
        }
    }

    .inline-icon {
        .badge-icon {
            position: relative;
            top: -8px;
        }
    }

    .badge-icon {
        float: right;
    }

    &__hotline {
        line-height: 1.4em;
        margin: rem-calc(10);

        a {
            line-height: 1.4em;
        }

        &--label {
            font-family: $header-font-family;
            font-size: rem-calc(12);
            text-transform: uppercase;
        }

        &--phone {
            font-family: $header-font-family;
            font-size: rem-calc(20);
        }

        &--email {
            font-size: rem-calc(10);
        }
    }

    &__mentions {
        font-size: rem-calc(9);
        margin: rem-calc(20 10 0);

        a {
            color: $gray-light;
        }
    }

    &__close {
        display: none;
        font-size: rem-calc(20);
        position: absolute;
        right: rem-calc(10);
        top: rem-calc(10);

        @include breakpoint(large down) {
            display: block;
        }
    }
}


.menu-overlay {
    @include background-opacity($black, .8);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: opacity 500ms ease;
    width: 100%;
    z-index: 1100;

    .mobile-menu-open & {
        opacity: 1;
    }
}

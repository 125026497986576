body.test-drive-request {

    /* SR TABLE */

    $table-width: 100%;
    $column-1-width: 6%;
    $column-2-width: 12%;
    $column-3-width: 7%;
    $column-4-width: 7%;
    $column-5-width: 10%;
    $column-6-width: 10%;
    $column-7-width: 7%;
    $column-8-width: 12%;
    $column-9-width: 9%;
    $column-10-width: 11%;

    $column-1-width-mobile: 10%;
    $column-4-width-mobile: 20%;
    $column-6-width-mobile: 20%;
    $column-7-width-mobile: 25%;
    $column-8-width-mobile: 25%;


    .sr-table {

        &__header {
            @include breakpoint(small down) {
                margin-top: rem-calc(30);
            }
        }

        &__infinite {
            // sass-lint:disable no-vendor-prefixes
            @include breakpoint(small down) {
                height: calc(100vh - 360px);
            }
        }

        td:first-child,
        th:first-child {
            border-left: 0;
        }

        td:last-child,
        th:last-child {
            border-right: 0;
        }

        td:nth-child(1),
        th:nth-child(1) {
            width: $column-1-width;

            @include breakpoint(small down) {
                width: $column-1-width-mobile;
            }
        }

        td:nth-child(2),
        th:nth-child(2) {
            width: $column-2-width;

            @include breakpoint(small down) {
                display: none;
            }
        }

        td:nth-child(3),
        th:nth-child(3) {
            width: $column-3-width;

            @include breakpoint(small down) {
                display: none;
            }
        }

        td:nth-child(4),
        th:nth-child(4) {
            width: $column-4-width;

            @include breakpoint(small down) {
                width: $column-4-width-mobile;
            }
        }

        td:nth-child(5),
        th:nth-child(5) {
            width: $column-5-width;

            @include breakpoint(small down) {
                display: none;
            }
        }

        td:nth-child(6),
        th:nth-child(6) {
            width: $column-6-width;

            @include breakpoint(small down) {
                width: $column-6-width-mobile;
            }
        }

        td:nth-child(7),
        th:nth-child(7) {
            width: $column-7-width;

            @include breakpoint(small down) {
                width: $column-7-width-mobile;
            }
        }

        td:nth-child(8),
        th:nth-child(8) {
            width: $column-8-width;

            @include breakpoint(small down) {
                width: $column-8-width-mobile;
            }
        }

        td:nth-child(9),
        th:nth-child(9) {
            width: $column-9-width;

            @include breakpoint(small down) {
                display: none;
            }
        }

        td:nth-child(10),
        th:nth-child(10) {
            width: $column-10-width;

            @include breakpoint(small down) {
                display: none;
            }
        }
    }
}

.global-search {
    display: inline-block;
    position: relative;

    select {
        display: none;
    }

    .select2 {
        &-container {
            border-bottom: 1px solid $white;
            color: $white;
            display: block;
            padding: rem-calc(10);
            text-align: left;
            width: rem-calc(300);

            @include breakpoint(small down) {
                border: 0;
                padding: 0;
            }
        }
    }
}

body > .select2-container {
    &.select2-container--open {
        background: $black;
        display: block;
        z-index: 10000;
    }

    .select2-dropdown {
        color: $white;
        display: block;
        padding: rem-calc(10);
    }

    .select2-search__field {
        background: $white;
        border: 0;
        font-weight: bold;
        margin-bottom: 0;
    }

    .select2-results {
        &__options {
            margin: 0;
        }

        &__option {
            background: $white;
            border-top: 1px solid $gray-medium;
            color: $secondary-color;
            cursor: pointer;
            list-style: none;
            padding: rem-calc(10);

            &--highlighted {
                opacity: .9;
            }

            &:hover {
                background: $secondary-color;
                color: $white;
            }
        }

        &__message {
            background: transparent;
            color: $white;
            text-align: center;
        }
    }
}

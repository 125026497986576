// sass-lint:disable-all

@font-face {
    font-family: "font-icon";
    src: url('../fonts/font-icon.eot');
    src: url('../fonts/font-icon.eot?#iefix') format('eot'),
    url('../fonts/font-icon.woff') format('woff'),
    url('../fonts/font-icon.ttf') format('truetype'),
    url('../fonts/font-icon.svg#font-icon') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon,
[class^="icon-"],
[class*=" icon-"] {
    display: inline-block;
    font-family: "font-icon" !important;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: $paragraph-lineheight;
    position: relative;
    speak: none;
    text-transform: none;
    vertical-align: middle;


    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.round-icon {
    @include round-icon();
}

.circle-icon {
    @include circle-icon();
}

a.link-icon {
    @include link-icon();
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-phone:before { content: "\EA01" }
.icon-bell:before { content: "\EA02" }
.icon-callback:before { content: "\EA03" }
.icon-database:before { content: "\EA04" }
.icon-file:before { content: "\EA05" }
.icon-news:before { content: "\EA06" }
.icon-no-phone:before { content: "\EA07" }
.icon-bell-full:before { content: "\EA08" }
.icon-man:before { content: "\EA09" }
.icon-woman:before { content: "\EA0A" }
.icon-agenda:before { content: "\EA0B" }
.icon-pie:before { content: "\EA0C" }
.icon-flag:before { content: "\EA0D" }
.icon-flag-full:before { content: "\EA0E" }
.icon-cross:before { content: "\EA0F" }
.icon-phone-book:before { content: "\EA10" }
.icon-magnifier:before { content: "\EA11" }
.icon-arrow:before { content: "\EA12" }
.icon-pencil:before { content: "\EA13" }
.icon-mail:before { content: "\EA14" }
.icon-recall:before { content: "\EA15" }
.icon-logout:before { content: "\EA16" }
.icon-warning:before { content: "\EA17" }
.icon-woman-business:before { content: "\EA18" }
.icon-man-business:before { content: "\EA19" }
.icon-email:before { content: "\EA1A" }
.icon-gearings:before { content: "\EA20" }
.icon-concluded:before { content: "\EA21" }
.icon-flag-privi-full:before { content: "\EA22" }
.icon-flag-privi:before { content: "\EA23" }
.icon-portable:before { content: "\EA24" }

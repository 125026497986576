/* FILTERS SR TABLE */

.filter-fields,
.filter-label {
    display: inline-block;
    padding: rem-calc(15 0);
    position: relative;
    vertical-align: middle;

    input {
        margin: 0;
    }

    label {
        display: inline-block;
        font-size: rem-calc(11);
    }

    button {
        background-color: $white;
        border: 1px solid $gray-dark;
        color: $secondary-color;
        outline: none;
        padding: rem-calc(8 10 5);
        text-align: left;
        width: rem-calc(150);

        &[aria-expanded="true"] {
            border-width: 1px 1px 0;

            i {
                transform: rotate(180deg);
            }
        }

        i {
            float: right;
        }
    }

    .custom-checkbox {
        margin: rem-calc(0);
    }

    .dropdown-pane {
        background: $white;
        border-color: $gray-dark;
        border-radius: 0;

        label {
            border-bottom: 1px solid $secondary-color;
            display: block;
            padding: rem-calc(5 0);
            text-align: left;
            text-transform: uppercase;
        }

        .custom-checkbox {
            float: left;
            margin-right: 0;
        }
    }
}

.filter-label {
    font-family: $header-font-family;
    font-size: rem-calc(14);
    margin: rem-calc(0 10);
    text-transform: uppercase;


    @include breakpoint(small down) {
        display: block;
        margin: rem-calc(20 0 5);
        padding: 0;
    }
}

.filter-fields {
    @include breakpoint(small down) {
        padding: rem-calc(0);
    }

    .dropdown_origin{
        max-height: 350px;
        overflow-y: auto;
    }
}

.filter-submit {
    display: none;
}

.scroll-x-table {
    height: 45vh;
    overflow-x: auto;
    width: 100%;
}

/* SR TABLE */

.sr-table {
    border-collapse: collapse;
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;
    transition: opacity 500ms ease;
    width: 100%;

    td,
    th {
        border: 1px solid $gray-dark;
        word-break: break-all;

        @include breakpoint(xsmall down) {
            font-size: rem-calc(9);
        }

        &.col-phoneNumber {
            @include breakpoint(small down) {
                display: none;
            }
            .phoneNumber{
                p{
                    color: white;
                    width: 90%;
                    background: #00368F;
                    margin: auto;
                    padding: 5px 0;
                }
            }
            .rdv{
                p{
                    color: white;
                    width: 90%;
                    background: #351c75;
                    margin: auto;
                    padding: 5px 0;
                }
            }
        }
    }

    thead th {
        background: $gray;
        color: $secondary-color;
        padding: rem-calc(15 0);
    }

    tbody tr {
        cursor: pointer;

        & > td {
            border-color: $gray-medium;
            color: $black;
            padding: rem-calc(15 0);
        }
    }

    &__header {

        th {
            background: $gray;
            border-bottom: 0;
            border-top: 0;
            font-family: $header-font-family;
            padding: 0;
            position: relative;

            @include breakpoint(medium down) {
                padding: rem-calc(15 0);
            }
        }

        a,
        span {
            display: block;
            padding: rem-calc(15 0);
        }

        a {
            padding: rem-calc(15 20 15 10);

            @include breakpoint(xsmall down) {
                padding: rem-calc(15 0);
                text-decoration: underline;
            }
        }

        .th-order {
            height: rem-calc(32);
            left: 76%;
            margin-top: rem-calc(-16px);
            position: absolute;
            top: 50%;

            @include breakpoint(xsmall down) {
                display: none;
            }

            &--asc {
                i:first-child {
                    opacity: 1;
                }
            }

            &--desc {
                i:last-child {
                    opacity: 1;
                }
            }

            i {
                opacity: .6;
                position: absolute;

                &:first-child {
                    font-weight: 300;
                    top: 0;
                    transform: rotate(-180deg);
                }

                &:last-child {
                    bottom: 0;
                }
            }
        }

        &__print {
            font-family: $header-font-family;
            padding: 1rem 0;
            text-align: center;
            text-transform: uppercase;
        }
    }

    &__infinite {
        // sass-lint:disable no-vendor-prefixes

        @include breakpoint(small down) {
            height: calc(100vh - 330px);
            overflow: auto;
        }

        position: relative;
        transition: opacity 500ms ease-out;

        &::-webkit-scrollbar {
            display: none;  // Safari and Chrome
        }
        // sass-lint:enable no-vendor-prefixes

        .xhr-loading-data & {
            opacity: .3;
        }

        tbody {
            height: rem-calc(40);
            overflow: auto;
        }
    }

    &__ajax-loader {
        height: rem-calc(60);
        left: 50%;
        margin: rem-calc(-30 0 0 50);
        opacity: 0;
        position: fixed;
        top: 60%;
        transition: opacity 500ms ease-out, visibility 500ms ease-out;
        visibility: hidden;
        width: rem-calc(60);
        z-index: 1000;

        @include breakpoint(large down) {
            margin: rem-calc(-30 0 0 -30);
            position: absolute;
        }

        .xhr-loading-data & {
            opacity: 1;
            visibility: visible;
        }
    }

    &__footer {
        font-family: $header-font-family;
        padding: rem-calc(0 0 15 0);
        text-align: center;
        text-transform: uppercase;

        a {
            color: $gray-dark;
            display: inline-block;
            margin: rem-calc(0 15);
        }
    }

    /* Handle row states */

    .icon-td-table {
        color: $black;
        font-size: rem-calc(15);

        @include breakpoint(xsmall down) {
            font-size: rem-calc(10);
        }
    }

    /* WAITING */
    .td-state-priority_1,
    .td-state-priority_2,
    .td-state-priority_3 {
        background: $white;


        .icon-td-table {
            @extend .icon-flag-full;
            color: $primary-color;
        }
    }

    .td-state-priority {
        background: $white;

        &.delay-recent .icon-td-table {
            @extend .icon-flag-full;
            color: $primary-color;
        }

        &.delay-medium .icon-td-table {
            @extend .icon-flag-full;
            color: $secondary-color;
        }

        &.delay-old .icon-td-table {
            @extend .icon-flag;
        }
    }

    .td-state-priority_rdv {
        background: $white;

        &.delay-recent .icon-td-table {
            @extend .icon-flag-privi-full;
            color: $primary-color;
        }

        &.delay-medium .icon-td-table {
            @extend .icon-flag-privi-full;
            color: $secondary-color;
        }

        &.delay-old .icon-td-table {
            @extend .icon-flag-privi;
        }
    }

    .td-state-other_contactable {
        background: $white;

        .icon-td-table {
            @extend .icon-phone;
        }
    }

    /* PROGRESS */

    .td-state-phone_meeting {
        background: $bone;

        .icon-td-table {
            @extend .icon-phone-book;
        }
    }

    .td-state-to_recall {
        background: $bone;

        .icon-td-table {
            @extend .icon-recall;
            font-size: rem-calc(18);
        }
    }

    /* DONE */

    .td-state-test_drive_meeting,
    .td-state-meeting,
    .other_contactable,
    .td-state-not_contactable,
    .td-state-canceled,
    .td-state-not_interested,
    .td-state-number_not_attributed {
        background: $white;

        td,
        .icon-td-table {
            color: $gray-dark;
        }
    }

    // Meeting
    .td-state-test_drive_meeting,
    .td-state-meeting {
        .icon-td-table {
            @extend .icon-agenda;
        }
    }

    // Failure (Canceled, ....)
    .td-state-canceled,
    .td-state-not_interested,
    .td-state-number_not_attributed {
        .icon-td-table {
            @extend .icon-cross;
        }
    }

    // Not conctatable
    .td-state-not_contactable {
        .icon-td-table {
            @extend .icon-no-phone;
        }
    }

    .td-state-concluded_sell {
        .icon-td-table {
            @extend .icon-concluded;
        }
    }

}

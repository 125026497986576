// sass-lint:disable-all
/**
 * Works with the icon sass file generated by gulp font icon
 **/
@mixin round-icon($size: rem-calc(48), $color: $secondary-color, $bg-color: $primary-color) {
    background-color: $bg-color;
    border-radius: $size / 2;
    color: $color;
    display: inline-block;
    font-size: rem-calc($size * (1/2));
    height: $size;
    line-height: 1;
    text-align: center;
    transition: color 300ms ease-out;
    width: $size;

    i {
        color: $color;
        line-height: $size;
        vertical-align: middle;
    }
}

@mixin background-opacity($color, $opacity) {
    background: $color;
    background: rgba($color, $opacity);
}

@mixin circle-icon($size: 48px, $color: $primary-color, $bg-color: $secondary-color) {
    @include round-icon($size, $color, $bg-color);
    background-color: transparent;
    border: 1px solid $color;
}

@mixin link-icon($size: 48px, $color: $primary-color, $bg-color: $secondary-color) {
    text-decoration: none;

    &.circle-icon {
        @include circle-icon($size, $primary-color);

        &:hover {
            @include round-icon($size, $primary-color, $anchor-color);

            text-decoration: none;
        }
    }

    &.round-icon {
        &:hover {
            @include round-icon($size, $primary-color, $anchor-color);

            text-decoration: none;
        }
    }
}

/**
 * Keyframes mixin
 **/
@mixin keyframes($animation-name) {
    @keyframes #{$animation-name} {
        @content;
    }
}

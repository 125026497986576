form {
    transition: opacity 500ms ease-out;

    .xhr-form-loading & {
        opacity: .3;
    }
}

.form-message {
    color: darken($dark-gray, 20%);
    padding: rem-calc(0 20 10);
    text-align: right;
    width: 59%;

    i {
        background: $dark-gray;
        color: $white;
        font-size: rem-calc(18);
        height: rem-calc(24);
        line-height: rem-calc(24);
        text-align: center;
        width: rem-calc(24);
    }
}

fieldset legend {
    color: $secondary-color;
    float: left;
    font-size: rem-calc(14);
    font-weight: bold;
    line-height: 1.2em;
    margin-right: rem-calc(10);
    text-align: right;
    text-transform: uppercase;
    width: 57%;

    @include breakpoint(small down) {
        display: block;
        width: auto;

        label {
            text-align: center;
        }
    }
}

.form-group {
    display: block;
    margin: rem-calc(15 0);

    .form-label,
    .form-edit,
    .form-icon,
    .form-input {
        display: inline-block;
        padding: rem-calc(10 5);
        vertical-align: middle;
    }

    select {
        margin-bottom: 0;
    }

    .form-label {
        width: 59%;

        @include breakpoint(small down) {
            display: block;
            width: auto;

            label {
                text-align: center;
            }
        }
    }

    .form-input {
        width: 40%;

        @include breakpoint(small down) {
            display: block;
            text-align: center;
            width: auto;
        }

        input {
            margin-bottom: 0;
        }
    }

    &.has-error {
        ul {
            border: 1px solid get-color(alert);
            margin: 0;
            padding: 5px;

            li {
                color: get-color(alert);
                font-size: rem-calc(11);
                font-weight: bold;
                list-style: none;
            }
        }

        input,
        select {
            border-color: get-color(alert);
        }

        .form-label label {
            color: get-color(alert);
        }
    }

    &.custom-centered-radio {
        text-align: center;

        label {
            display: inline-block;
        }
    }

    /* Type field : Date
    /* ---------------------------- */

    &__date {
        text-align: center;

        .form-icon {
            cursor: pointer;
        }

        .form-icon,
        .form-label,
        .form-input {
            display: inline-block;
            width: auto;
        }

        .form-label {
            @include breakpoint(small down) {
                display: block;
                text-align: center;
                width: auto;
            }
        }

        i {
            font-size: rem-calc(24);
            line-height: 35px;
        }

        .form-icon {
            @include breakpoint(medium down) {
                display: none;
            }
        }
    }

    /* Type field : Datetime
    /* ---------------------------- */

    &__datetime {
        text-align: center;

        & > div {
            width: 100%;
        }

        &.no-label > div,
        &.no-label > div > label {
            display: inline-block;
            margin: rem-calc(0 10 0 0);
            vertical-align: middle;
            width: auto;

            .form-datetime {
                display: inline-block;
                vertical-align: middle;
            }
        }

        .form-datetime {
            text-align: left;

            & > div {
                display: inline-block;
            }

            select {
                display: inline-block;
                margin: rem-calc(0 3);
                width: auto;
            }

            input {
                display: inline-block;
                margin: 0;
                width: auto;
            }

            i {
                display: inline-block;
                font-size: rem-calc(24);
                line-height: 35px;
                margin: rem-calc(0 10);
            }
        }
    }

    /* Type field : Submit
    /* ---------------------------- */

    &__submit {
        background: $gray-dark;
        bottom: 0;
        color: $white;
        left: 0;
        padding: 10px;
        position: fixed;
        transition: opacity 500ms ease-out;
        width: 100%;
        z-index: 100;

        .xhr-form-loading & {
            opacity: 0;
        }

        & > div {
            display: inline-block;
        }

        .btn-print {
            color: $white;
            display: inline-block;
            font-family: $header-font-family;
            padding: rem-calc(0 10);
            text-transform: uppercase;
        }
    }

    /* Type field : Locked
    /* ---------------------------- */

    &__locked {
        margin: rem-calc(5 0);

        .form-label,
        .form-input {
            label {
                color: $gray-dark;
                font-family: $header-font-family;
                font-size: rem-calc(10);
                text-align: right;
                vertical-align: middle;
            }
        }

        .form-input .radio-label label {
            margin-right: 4px;
        }

        .button {
            background: $gray-dark;
            border: 1px solid $gray-dark;
            font-size: rem-calc(20);
            height: rem-calc(30);
            line-height: rem-calc(26);
            padding: 0;
            width: rem-calc(30);
        }

        input {
            font-size: rem-calc(10);
            height: rem-calc(30);
            margin: 0;
            padding: rem-calc(0 3);

            &[disabled="disabled"] {
                opacity: .6;
            }
        }

        .form-label,
        .form-input,
        .form-edit {
            display: inline-block;
            padding: rem-calc(5 0);
        }

        .form-label {
            width: 25%;
        }

        .form-input {
            width: 55%;
        }

        .form-edit {
            text-align: right;
            width: 20%;
        }
    }

    /* Type field : default label
    /* ---------------------------- */

    label {
        color: $secondary-color;
        font-size: rem-calc(14);
        font-weight: bold;
        line-height: 1.2em;
        margin-right: rem-calc(10);
        text-align: right;
        text-transform: uppercase;

        &.label-left {
            text-align: left;
        }

        .orange & {
            color: $primary-color;
        }
    }
}

textarea {
    min-height: rem-calc(120);
}

button {
    @extend .button;
}

select {
    background-color: $white;
}

/* RADIO CONTROL
/* ----------------------------- */

form  {
    .custom-checkbox__container {
        display: inline-block;
    }

    .custom-checkbox,
    .custom-radio {
        cursor: pointer;
        display: inline-block;
        font-weight: normal;
        margin: rem-calc(0);
        padding: rem-calc(0);
        position: relative;

        &:hover > .inner {
            opacity: .5;
        }

        label {
            cursor: pointer;
            display: inline-block;
            font-style: italic;
            font-weight: normal;
            padding-left: 0;
            padding-right: rem-calc(20);
        }

        // Native input control and checked events
        input {
            left: 0;
            opacity: 0;
            position: absolute;
            visibility: hidden;

            &:checked + .outer > .inner {
                opacity: 1;
                transform:  scale(1);
            }

            &:disabled + .outer {
                opacity: .6;
            }
        }

        // Outer ring of custom radio
        .outer {
            background-color: $gray-light;
            border: 1px solid $secondary-color;
            display: inline-block;
            height: rem-calc(16);
            margin-right: rem-calc(5);
            padding: rem-calc(1);
            vertical-align: top;
            width: rem-calc(16);
        }

        // Inner ring of custom radio
        .inner {
            background-color: $primary-color;
            display: block;
            height: rem-calc(12);
            opacity: 0;
            transform: scale(0);
            transition: transform .25s ease-in-out, opacity .25s ease-in-out;
            width: rem-calc(12);
        }

        &.custom-radio {
            .outer {
                border-radius: 50%;
                height: rem-calc(16);
                width: rem-calc(16);
            }

            .inner {
                border-radius: 50%;
                height: rem-calc(12);
                width: rem-calc(12);
            }
        }

        @include breakpoint(medium down) {
            .outer {
                float: none;
                margin-top: 0;
            }
        }
    }

    .radio-label {
        display: inline-block;
        width: auto;
    }
}

/* AJAX LOADER */

.form-ajax-loader {
    height: rem-calc(60);
    left: 50%;
    margin: rem-calc(-30 0 0 -30);
    opacity: 0;
    position: absolute;
    top: 50%;
    transition: opacity 500ms ease-out, visibility 500ms ease-out;
    visibility: hidden;
    width: rem-calc(60);
    z-index: 100;

    .xhr-form-loading & {
        opacity: 1;
        visibility: visible;
    }
}

.button-checkbox {
    cursor: pointer;
    display: inline-block;
    font-weight: normal;
    margin: 0;
    position: relative;

    :hover > .inner {
        opacity: .5;
    }

    label > span {
        background: $gray-light;
        border: 1px solid $gray-medium;
        border-radius: $button-radius;
        color: $prussian-blue;
        cursor: pointer;
        display: inline-block;
        font-weight: 600;
        margin: 0;
        padding: rem-calc(2 10);
    }

    // Native input control and checked events
    input {
        left: 0;
        opacity: 0;
        position: absolute;

        &:checked + span {
            background: $gray-medium;
            border-color: $gray-medium;
            color: $gray-light;
        }
    }
}

/* SWITCH RADIO */
form {
    .switch-radio {
        background: $pampas;
        display: table;
        margin: rem-calc(20) auto;
        position: relative;
        table-layout: fixed;
        width: 100%;

        @include breakpoint(small down) {
            background: transparent;
            display: block;
        }

        label {
            background: $pampas;
            color: $secondary-color;
            cursor: pointer;
            display: table-cell;
            font-family: $header-font-family;
            font-size: rem-calc(14);
            margin: 0;
            padding: rem-calc(10 0);
            position: relative;
            text-align: center;
            text-transform: uppercase;
            transition: background 300ms ease;
            vertical-align: middle;
            z-index: 2;

            @include breakpoint(small down) {
                display: block;
                margin: rem-calc(10 0);
            }

            &:hover {
                background-color: $gray;
            }
        }

        .outer {
            display: none;
        }

        input {
            display: none;

            &:checked + label {
                background: $secondary-color;
                color: $white;
                font-weight: bold;
                transition: .15s ease-out;
                transition-property: color, text-shadow;
            }
        }

        select {
            margin-bottom: 0;
        }
    }
}

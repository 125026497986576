.bar-data {
    display: inline-block;
    margin: rem-calc(30 0);
    position: relative;
    width: 100%;

    &__title {
        background: $gray-light;
        display: table;
        height: rem-calc(50);
        width: 100%;

        h5 {
            display: table-cell;
            font-size: rem-calc(16);
            padding: rem-calc(0 15);
            vertical-align: middle;
        }

    }

    &__legend {
        &__item {
            display: table;
            margin-bottom: rem-calc(10);

            &--first {
                .bar-data__legend__item--mark span {
                    background: $secondary-color;
                }
            }

            &--second {
                .bar-data__legend__item--mark span {
                    background: $gray-light;
                }
            }

            span {
                display: table-cell;
                vertical-align: middle;
            }

            &--mark {
                span {
                    border-radius: rem-calc(10);
                    display: inline-block;
                    height: rem-calc(10);
                    margin-right: rem-calc(5);
                    vertical-align: middle;
                    width: rem-calc(30);
                }
            }

            &--label {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }

    &__graph {
        height: rem-calc(120);
        margin: rem-calc(30 0);
        position: relative;
    }

    &__axis {
        &--item {
            border-bottom: 1px dashed $gray-dark;
            left: rem-calc(30);
            position: absolute;
            width: calc(100% - 30px);

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(2) {
                top: 50%;
            }

            &:nth-child(3) {
                top: 100%;
            }

            span {
                display: block;
                margin-left: rem-calc(-40);
                margin-top: rem-calc(-10);
                position: absolute;
                text-align: right;
                width: rem-calc(30);
            }
        }
    }

    &__bars {
        &__item {
            border-top-left-radius: rem-calc(25);
            border-top-right-radius: rem-calc(25);
            bottom: 0;
            left: 50%;
            margin: 0 5%;
            position: absolute;
            text-align: center;
            width: rem-calc(50);

            &--left {
                background: $secondary-color;
                margin-left: rem-calc(-40);

                span {
                    color: $white;
                }
            }

            &--right {
                background: $gray-light;
                margin-left: rem-calc(20);

                span {
                    color: $secondary-color;
                }
            }

            span {
                display: block;
                font-family: $header-font-family;
                font-size: rem-calc(20);
                font-weight: bold;
                line-height: rem-calc(20);
                margin-top: rem-calc(-7);
                position: relative;
                top: 50%;


                &.small-value {
                    color: $secondary-color;
                    margin-top: -20px;
                    top: 0;
                }
            }
        }
    }
}

@page {
    margin: rem-calc(30 15);
}


@media print {
    header,
    .breadcrumb a,
    .back-button,
    .context-menu,
    .main-menu,
    .form-group__submit {
        display: none;
    }

    body {
        color: $black;
        font-size: 120%;
        height: auto;
        overflow: auto;
    }

    h4 {
        font-size: 1.4rem;
    }

    .content {
        background: $gray-light;
        height: auto;
        margin-left: 0;
        overflow: auto;
    }

    .row {
        & > .columns {
            padding: 0;
        }
    }

    .sr-table {
        tbody > tr>td {
            font-size: rem-calc(12);
            height: 20px;
            padding: rem-calc(5) !important; //sass-lint:disable-line no-important
            text-transform: lowercase;
        }

        &__header {
            tr > th {
                border: 1px solid $dark-gray;
            }
        }

        &__infinite {
            height: auto !important; //sass-lint:disable-line no-important
        }

        &__footer {
            display: none;
        }
    }

    .panel {
        border: 1px solid $gray-light;
        padding: 0;
    }

    /* FOLLOW UP DETAILS */

    .follow-up-detail {
        .request-user__car {
            border: 1px solid $black;
        }

        .accordion {
            .accordion-title {
                border-bottom: 1px solid $black;
                margin-bottom: rem-calc(10);
                padding: 5px 0 !important; //sass-lint:disable-line no-important
                text-align: left;
                text-decoration: none;

                i {
                    display: none;
                }
            }

            .accordion-content {
                padding: 0;

                .form-input,
                .form-label,
                .form-label > label {
                    display: block;
                    padding: 0;
                    text-align: left;
                    width: auto;
                }

                .form-label {
                    margin: rem-calc(10 0 5);
                }

                .form-edit {
                    display: none;
                }
            }
        }

        .panel--help-argument {
            display: none;
        }
    }

    /* CAMPAIGN DETAILS */

    .campaign-details.print-page {
        .columns {
            float: none;
            text-align: left;
            width: 100%;
        }

        .filter-label {
            margin: 0;
            padding: rem-calc(15 0 5);
        }

        .filter-fields {
            display: block;
            padding: 0;

            button {
                display: none;
            }
        }

        .dropdown-pane {
            border: 0;
            margin: 0;
            padding: 0;
            position: relative;
            visibility: visible;
            width: 100%;

            label {
                border: 0;
                display: inline-block;
                float: none;
            }
        }

        .sr-table td {
            text-transform: uppercase;
        }
    }

    /* FORMS */

    form {
        .switch-radio {
            border: 1px solid $black;

            label {
                color: lighten($black, 40%);
            }

            input:checked + label {
                color: $black;
                text-decoration: underline;
            }
        }

        .custom-checkbox,
        .custom-radio {
            input:checked+.outer {
                position: relative;

                &::before {
                    color: $black;
                    content: "x";
                    font-family: Arial, sans-serif;
                    left: 3px;
                    position: absolute;
                    top: -3px;
                    z-index: 100;
                }
            }
        }
    }
}

.h2 {
    font-size: rem-calc(40);
    font-weight: bold;

    @include breakpoint(medium down) {
        font-size: rem-calc(27);
    }

    @include breakpoint(small down) {
        font-size: rem-calc(25);
    }

    &-divided {
        line-height: 1;
        padding: rem-calc(65 0);
        position: relative;

        &::before,
        &::after {
            background-color: $gray-light;
            content: "";
            height: rem-calc(2);
            left: 0;
            position: absolute;
            width: rem-calc(200);
        }

        &.text-center {
            &::before,
            &::after {
                left: calc(50% - 100px);
            }
        }

        &::before {
            top: rem-calc(45);
        }

        &::after {
            bottom: rem-calc(45);
        }

        &.orange {
            &::before,
            &::after {
                background-color: $primary-color;
            }
        }

        span {
            color: $body-font-color;
        }
    }
}

.h3 {
    font-size: rem-calc(25);
    font-weight: bold;

    @include breakpoint(medium down) {
        font-size: rem-calc(22);
    }

    @include breakpoint(small down) {
        font-size: rem-calc(20);
    }

    &-divided {
        @extend .h2-divided;

        padding: rem-calc(35 0);

        &::before,
        &::after {
            width: rem-calc(110);
        }

        &::before {
            top: rem-calc(10);
        }

        &::after {
            bottom: rem-calc(10);
        }

        &.text-center {
            &::before,
            &::after {
                left: calc(50% - 55px);
            }
        }
    }
}


.h4 {
    font-family: $header-font-family;
    font-size: rem-calc(20);
    font-weight: bold;

    @include breakpoint(medium down) {
        font-size: rem-calc(20);
    }

    @include breakpoint(small down) {
        font-size: rem-calc(20);
    }
}

a {
    transition: color 250ms ease;

    .icon-arrow-bold-left {
        font-size: rem-calc(12);
        margin-left: rem-calc(5);
        transition: margin 250ms ease;
        vertical-align: inherit;
    }

    &:hover .icon-arrow-bold-left {
        margin-left: rem-calc(15);
    }
}

.text-upper {
    text-transform: uppercase;
}

.text-notransform {
    text-transform: none;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-bold {
    font-weight: bold;
}

.text-big {
    font-size: rem-calc(22);
    font-weight: bold;
}

.white-link {
    color: $gray-light;

    &:hover,
    &:focus {
        color: $gray-medium;
    }
}

.orange-block-title {
    @extend .h3;

    background: $primary-color;
    color: $gray-light;
    display: block;
    font-weight: bold;
    margin-bottom: rem-calc(30);
    padding: rem-calc(15 30 12);
    text-align: center;
    text-transform: uppercase;
}

.no-break-work {
    white-space: nowrap;
}

.breadcrumb {
    padding: rem-calc(0 0 15 0);

    ul {
        margin: rem-calc(0);
        padding: 0;
    }

    li {
        color: $secondary-color;
        display: inline-block;
        font-weight: bold;
        list-style: none;
        text-transform: uppercase;
    }

    & > div.back-button {

        color: $secondary-color;
        font-family: $header-font-family;
        font-size: rem-calc(16);
        margin-top: rem-calc(15);
        text-transform: uppercase;

        &:last-child:not(:first-child) {
            float: none;
        }

        a {
            display: inline-block;
        }

        i {
            transform: rotate(90deg);
        }
    }
}

.header.header-mobile {
    background: $gray-medium;
    display: none;
    z-index: 1000;

    @include breakpoint(large down) {
        display: block;
    }

    .top-bar {
        background: $gray-medium;

        &-left,
        &-right {
            width: auto;
        }

        &-center {
            display: inline-block;
            text-align: right;
            width: calc(100% - 110px);

            @include breakpoint(large down) {
                .mobile-menu-open & {
                    //display: none;
                }
            }
        }

        &-right {
            @include breakpoint(large down) {
                .mobile-menu-open & {
                    //display: none;
                }
            }
        }

        &-burger {
            display: block;
            height: rem-calc(45);
            position: relative;
            text-align: center;
            width: rem-calc(64);
        }

        .burger {
            margin-left: 16px;
            margin-top: -2px;
            top: 50%;

            &,
            &::before,
            &::after {
                background: $secondary-color;
                border-radius: 3px;
                height: 4px;
                left: 0;
                opacity: 1;
                position: absolute;
                width: 32px;
            }

            &::before {
                content: "";
                top: 10px;
            }

            &::after {
                bottom: 10px;
                content: "";
            }
        }

        &-search {
            padding: rem-calc(5 10);

            .global-search {
                border-bottom: 1px solid $black;
                display: inline-block;
                height: rem-calc(32);
                max-width: rem-calc(300);
                opacity: 0;
                overflow: hidden;
                transition: width 500ms ease-in, opacity 800ms ease-out;
                vertical-align: middle;
                width: 0;

                .select2-container {
                    color: $black;
                }

                .mobile-search-open & {
                    opacity: 1;
                    width: calc(100vw - 164px);
                }
            }

            i {
                color: $black;
                font-size: rem-calc(20);
                margin-top: rem-calc(3);
            }
        }

        &-agenda {
            display: inline-block;
            height: rem-calc(45);
            padding: rem-calc(6 0);
            width: rem-calc(45);

            &__label {
                display: none;
            }

            &__badge {
                position: absolute;
                right: rem-calc(3);
                top: rem-calc(3);
                z-index: 10;

                .badge-icon {
                    @include badge-icon($secondary-color, $gray-light, 20);
                }
            }
        }
    }
}

.campaign-details.wave-details {

    /* SR TABLE */

    $table-width: 100%;
    $column-1-width: 10%;
    $column-2-width: 8%;
    $column-3-width: 14%;
    $column-4-width: 14%;
    $column-5-width: 8%;
    $column-6-width: 12%;
    $column-7-width: 12%;
    $column-8-width: auto;

    $column-1-width-mobile: 16%;
    $column-2-width-mobile: 16%;
    $column-3-width-mobile: 26%;
    $column-4-width-mobile: 26%;
    $column-5-width-mobile: 16%;

    $column-2-width-business: 16%;
    $column-3-width-business: 16%;
    $column-4-width-business: 16%;
    $column-5-width-business: 8%;
    $column-6-width-business: 8%;
    $column-7-width-business: 8%;

    .sr-table {

        &__header {
            @include breakpoint(small down) {
                margin-top: rem-calc(20);
            }
        }

        &__infinite {
            @include breakpoint(small down) {
                height: calc(100vh - 315px);
            }
        }

        td:first-child,
        th:first-child {
            border-left: 0;
        }

        td:last-child,
        th:last-child {
            border-right: 0;
        }

        td:nth-child(1),
        th:nth-child(1) {
            width: $column-1-width;

            @include breakpoint(small down) {
                width: $column-1-width-mobile;
            }
        }

        &:not(.business) {
            td:nth-child(2),
            th:nth-child(2) {
                width: $column-2-width;

                @include breakpoint(small down) {
                    width: $column-2-width-mobile;
                }
            }

            td:nth-child(3),
            th:nth-child(3) {
                width: $column-3-width;

                @include breakpoint(small down) {
                    width: $column-3-width-mobile;
                }
            }

            td:nth-child(4),
            th:nth-child(4) {
                width: $column-4-width;

                @include breakpoint(small down) {
                    width: $column-4-width-mobile;
                }
            }

            td:nth-child(5),
            th:nth-child(5) {
                width: $column-5-width;

                @include breakpoint(small down) {
                    width: $column-5-width-mobile;
                }
            }

            td:nth-child(6),
            th:nth-child(6) {
                width: $column-6-width;

                @include breakpoint(small down) {
                    display: none;
                }
            }

            td:nth-child(7),
            th:nth-child(7) {
                width: $column-7-width;

                @include breakpoint(small down) {
                    display: none;
                }
            }
        }

        td:nth-child(8),
        th:nth-child(8) {
            width: $column-8-width;

            @include breakpoint(small down) {
                display: none;
            }
        }

        &.business {
            td {
                word-break: keep-all;
            }

            td:nth-child(2),
            th:nth-child(2) {
                width: $column-2-width-business;

                @include breakpoint(small down) {
                    width: $column-2-width-mobile;
                }
            }

            td:nth-child(3),
            th:nth-child(3) {
                width: $column-3-width-business;

                @include breakpoint(small down) {
                    width: $column-3-width-mobile;
                }
            }

            td:nth-child(4),
            th:nth-child(4) {
                width: $column-4-width-business;

                @include breakpoint(small down) {
                    width: $column-4-width-mobile;
                }
            }

            td:nth-child(5),
            th:nth-child(5) {
                width: $column-5-width-business;

                @include breakpoint(small down) {
                    width: $column-5-width-mobile;
                }
            }

            td:nth-child(6),
            th:nth-child(6) {
                width: $column-6-width-business;

                @include breakpoint(small down) {
                    display: none;
                }
            }

            td:nth-child(7),
            th:nth-child(7) {
                width: $column-7-width-business;

                @include breakpoint(small down) {
                    display: none;
                }
            }
        }
    }
}

.calendar-legend {
    margin: rem-calc(30 0);

    &-block {
        float: left;
        margin: 10px;
    }

    &-color {
        float: left;
        min-height: 20px;
        width: 20px;
    }

    &-label {
        display: inline-block;
        margin-left: 5px;
        margin-top: 3px;
        min-height: 20px;
    }
}

.calendar__cta {
    margin: rem-calc(30 0);
    text-align: right;
}

.confRdvInfo-modal{
    text-align: left;
    .wishRdv{
        margin: 5px 0;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .sendDate{
        font-size: 11px;
        margin-bottom: 5px;
    }
    .infoClient
    {
        font-weight: bold;
        font-size: 12px;
    }
    .typeRdv
    {
        font-size: 13px;
        margin-bottom: 5px;
    }
}

.datepicker-days td:hover,
.datepicker-months td span:hover,
.datepicker-years td span:hover {
    background: $gray-dark !important;
}

.datepicker td.active.day,
.datepicker td.active.year,
.datepicker td span.active {
    background-color: $bleu;
    color: $white;
}

html {
    min-height: 100%;
}

body {
    overflow: hidden;
}

.content {
    background: $gray-light;
    height: calc(100vh - 64px);
    margin-left: rem-calc(128);
    overflow: auto;
    padding: rem-calc(15 45);

    @include breakpoint(large down) {
        margin-left: 0;
        padding: rem-calc(30 0);
    }

    &__full {
        margin-left: 0;
        padding-bottom: rem-calc(80);
    }
}

.display-table {
    display: table;
    height: 100%;
    width: 100%;
}

.display-cell {
    display: table-cell;
    height: 100%;
    vertical-align: middle;

    &.vbottom {
        vertical-align: bottom;
    }
}

.orange {
    color: $primary-color;
}

.tallow {
    color: $tallow;
}

.white {
    color: $gray-light;
}

.text-left {
    text-align: left;
}

.no-margin {
    margin: 0;
}

.button {
    font-weight: bold;
    text-transform: uppercase;
}

/* DEFAULT TABLE STYLE */

table,
.unstriped {
    border-collapse: collapse;

    thead  {
    }

    th {
        background: $gray-light;
        border-bottom: 1px solid $gray-medium;
        text-align: center;
    }

    tbody {
        tr > td {
            border-bottom: 1px solid $gray-medium;
            transition: background 300ms ease;
        }
    }

    .highlighted {
        background: $primary-color;
        border-color: $primary-color;
        color: $white;
    }

    .table-picto-actions {
        a {
            display: inline-block;
            margin: rem-calc(0 5);
            text-transform: uppercase;
            vertical-align: middle;
        }

        i {
            font-size: rem-calc(22);
            margin-bottom: rem-calc(5);
        }
    }
}

/* Scroll Top button */

.scroll-top-wrapper {
    background: $primary-color;
    border: 1px solid $white;
    border-radius: $global-radius;
    bottom: 10px;
    color: $white;
    height: 48px;
    line-height: 1em;
    opacity: 0;
    padding: 15px;
    position: fixed;
    right: 10px;
    text-align: center;
    transition: background .5s ease-in-out;
    visibility: hidden;
    width: 48px;
    z-index: 99999999;

    &:hover {
        background-color: lighten($primary-color, 20%);
    }

    &.show {
        cursor: pointer;
        opacity: 1;
        visibility: visible;
    }
}

.bottom-padded {
    padding-bottom: 15px;
}


.show-on-desktop {
    @include breakpoint(small down) {
        display: none;
    }
}

.show-on-mobile {
    @include breakpoint(medium up) {
        display: none;
    }
}

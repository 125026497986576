.operation-index {
    &__list {
        padding-top: rem-calc(10);
    }

    &__item {
        padding: rem-calc(0 15);
        text-align: center;
        transition: opacity 300ms ease;

        &:hover {
            opacity: .8;
        }

        &--title {
            padding: rem-calc(15 0);
            text-align: center;
            text-transform: uppercase;
        }
    }

    &__dropdown {
        background: $secondary-color;
        color: $white;
        padding: rem-calc(15);

        &--title {
            text-transform: uppercase;
        }

        &--select {
            margin: rem-calc(45 0);
        }

        &--cta {
            text-align: center;
        }
    }
}

.information {
    background-color: $gray;
    display: flex;
    float: right;
    padding: 1rem 1rem 0;
    width: 20rem;
}

.bloc_sad{
    font-family: Arial, "sans-serif";
    background: #f1f1f1;
    padding: 5px 20px;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}
.ico_sad a img{
    width: 20px;
}
.txt_sad a{
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    padding-left: 10px;
    display: block;
}

.btn-mail-div {
    display : flex;
    flex-direction: column;
    align-items: center;
}
.btn-mail{
    display: inline-block;
    vertical-align: middle;
    width: 325px;
    margin: 10px 0;
    padding:6px 13px;
    color : #fff !important ;
    -webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 0;
    -webkit-transition: background-color 0.25s ease-out,color 0.25s ease-out;
    -o-transition: background-color 0.25s ease-out,color 0.25s ease-out;
    transition: background-color 0.25s ease-out,color 0.25s ease-out;
    font-size: 1rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: #000000;


}
.btn-mail:hover{
    color : #fff ;
    background: #00368F;
}

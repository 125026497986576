.context-menu {
    position: absolute;
    right: 0;
    z-index: 100;

    button {
        background: $white;
        box-shadow: 0 0 10px $gray-dark;
        color: $secondary-color;
        font-weight: normal;
        height: rem-calc(25);
        line-height: rem-calc(25);
        padding: rem-calc(0 20);
        position: relative;

        &::before {
            border-color: transparent $white transparent transparent;
            border-style: solid;
            border-width: 0 25px 25px 0;
            content: "";
            height: 0;
            left: rem-calc(-26);
            position: absolute;
            top: rem-calc(-1);
            width: 0;
        }

        &:active,
        &:focus,
        &:hover {
            background: $white;
            color: $secondary-color;
        }

        i {
            color: $primary-color;
            margin-left: rem-calc(10);
            vertical-align: text-bottom;
        }
    }

    .dropdown-pane {
        background: $white;
        border: 0;
        border-radius: 0;
        box-shadow: 0 3px 20px $gray-dark;
        left: inherit !important; //sass-lint:disable-line no-important
        max-height: 80vh;
        overflow: auto;
        padding: rem-calc(15);
        right: 0;
        top: 0  !important; //sass-lint:disable-line no-important
        width: rem-calc(300);

        a {
            display: block;
        }

        i {
            color: $primary-color;
            float: right;
            font-size: rem-calc(12);
        }

        .is-active > a > i {
            transform: rotateX(180deg);
        }

        & > .accordion {
            font-weight: bold   ;
            text-transform: uppercase;

            & > li {
                a {
                    margin-left: rem-calc(15);
                }

                & > a {
                    border-bottom: 1px solid $secondary-color;
                    margin-left: rem-calc(0);
                    padding: rem-calc(5 0);
                }

                & > .accordion-content {
                    padding: rem-calc(10 0);
                }

                li {
                    > a {
                        border-bottom: 1px solid $gray-light;
                        margin-left: rem-calc(0);
                        padding: rem-calc(3 0);
                    }

                    .accordion-content {
                        padding: rem-calc(10 0);

                        a {
                            border: 0;
                        }
                    }
                }
            }
        }
    }
}

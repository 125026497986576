.pager {
    display: block;
    text-align: center;

    & > div {
        display: inline-block;

        & > span,
        & > form {
            display: inline-block;
        }
    }
}

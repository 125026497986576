.panel {
    background: $white;
    box-shadow: 0 1px 5px $gray-medium;
    margin-bottom: rem-calc(30);
    padding: rem-calc(20 20 0);
    position: relative;

    @include breakpoint(xsmall down) {
        margin-bottom: rem-calc(15);
        padding: rem-calc(10 10 0);
    }

    &.no-title  {
        padding: rem-calc(0 15);
    }

    &_comment {
        background: #f3f1ee;
        line-height: 2em;
        padding: rem-calc(20 20 0);

        h3 {
            text-transform: uppercase;
        }
    }

    &_title {
        border-bottom: 1px solid $bone;
        display: table;
        height: 50px;
        padding-bottom: rem-calc(15);
        width: 100%;

        i {
            font-size: rem-calc(26);
            margin-right: rem-calc(10);
        }

        h4 {
            font-size: rem-calc(18);
            padding-top: rem-calc(2px);
        }

        h4,
        span {
            display: table-cell;
            line-height: 1.2em;
            vertical-align: middle;
        }

        span {
            vertical-align: top;
            width: rem-calc(32);
        }

        &--main {
            display: block;
            font-size: rem-calc(18);
            font-weight: bold;
            margin-bottom: rem-calc(15);
        }

        &--form {
            display: table-cell;
            text-align: right;
            vertical-align: middle;
            width: rem-calc(300);

            .form-label,
            .form-input {
                display: inline-block;
                padding: 0;
                width: auto;
            }

            .form-group {
                margin: 0;
            }

            select,
            input {
                margin: 0;
                min-width: rem-calc(80);
            }
        }
    }

    &_content {
        padding: rem-calc(15 0);

        .panel--ception & {
            padding: rem-calc(15 0);
        }

        &.no-margin {
            padding: rem-calc(15 0);
        }

        /* STATS */

        .stat-label {
            color: $gray-dark;
            font-family: $header-font-family;
            font-size: rem-calc(14);
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;

            &.text-left {
                text-align: left;
            }

            &.orange {
                color: $primary-color;
            }
        }

        .stat-value {
            font-family: $header-font-family;
            font-size: rem-calc(38);
            font-weight: bold;
            line-height: 1em;
            text-align: center;

            &.big {
                font-size: rem-calc(56);

                @include breakpoint(small down) {
                    font-size: rem-calc(42);
                }
            }
        }

        .panel {
            background: $gray-light;
            color: $black;
            margin-top: rem-calc(15);

            .panel--ception & .panel_content {
                padding: rem-calc(15 30);

                @include breakpoint(medium down) {
                    padding: rem-calc(15 0);
                }
            }

            &_cta  .button {
                background: $black;
                color: $white;
                font-family: $header-font-family;
            }
        }
    }

    &_cta {
        text-align: center;

        .button {
            @include breakpoint(small down) {
                margin-bottom: rem-calc(10);
            }
        }
    }

    &__stats {
        .panel_title {
            border: 0;
            padding: 0;

            h5 {
                font-size: rem-calc(15);
            }

            @media screen and (max-width: 1400px) {
                h5 {
                    font-size: rem-calc(12);
                }
            }
        }

        &--cols {
            .columns {
                border-right: 1px solid $gray;

                &:last-child {
                    border: 0;
                }
            }

            &--top {
                margin-bottom: 10px;
                margin-top: auto;
            }

            &--middle {
                margin: auto;
            }

            &--bottom {
                margin-top: auto;
            }
        }

        &--versus {
            padding: rem-calc(30 15);

            &.no-margin {
                padding: 0;
            }

            &-label {
                font-family: $header-font-family;
                font-size: rem-calc(18);
                font-weight: bold;
            }

            .display-table {
                margin: 0 auto;
                text-align: left;
                width: auto;

                @include breakpoint(medium down) {
                    text-align: center;
                }

                &.no-margin {
                    margin: 0;
                }

                .no-title & {
                    margin: rem-calc(15) auto 0;
                }
            }

            .stat-label,
            .stat-value {
                display: inline-block;
            }

            .stat-value {
                margin-right: 10px;

                @include breakpoint(medium down) {
                    margin: rem-calc(0);
                }
            }

            .panel__stats--versus-label {
                margin: rem-calc(0 30);

                @include breakpoint(medium down) {
                    display: block;
                    margin: rem-calc(0 10);
                }
            }
        }
    }

    &--blue {
        background: $secondary-color;
        color: $white;
    }
}

@charset "utf-8";

@import "../../../../../node_modules/foundation-sites/scss/foundation";
@import "../../../../../node_modules/foundation-datepicker/scss/foundation-datepicker";

@import "common/settings";

@include foundation-everything;

@import "common/mixins";
@import "common/icons";
@import "common/fonts";
@import "common/common";
@import "common/buttons";
@import "common/forms";
@import "common/print";

@import "components/badge-icon";
@import "components/bar-data";
@import "components/breadcrumb";
@import "components/fullcalendar";
@import "components/global-search";
@import "components/modals";
@import "components/panel";
@import "components/table";
@import "components/typography";

@import "views/Layout/context-menu";
@import "views/Layout/header";
@import "views/Layout/header-mobile";
@import "views/Layout/menu";
@import "views/Layout/modals";
@import "views/Layout/sidebar";

@import "views/Calendar/index";
@import "views/Home/index";
@import "views/MailOperation/campaign-select";
@import "views/MailOperation/mails-campaign-details";
@import "views/Operation/index";
@import "views/Operation/campaign-details";
@import "views/Pager/pager";
@import "views/Prospection/wave-details";
@import "views/Security/login";
@import "views/Stats/stats-details";
@import "views/TestDriveRequest/index";
@import "views/TestDriveRequest/follow-up-details";

.medium-20 {
  width: 20%;
}
#td.col-phoneNumber p{
  color : white ;
  background-color: #00368F;
  margin : auto;
}
#btn-delegation{
  background: #00368F;
  color: white;
}
.cancelDelegation{
  margin-left: 94%;
  margin-bottom: 1%;
  p{
    font-size: 2em;
  }
}
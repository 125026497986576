.block_fiche_relance{
    .btn-saleConcluded{
        text-align: right;
        button{
            background:$black;
            color:white;
          &:hover{
            background:$bleu;
          }
        }
    }
}

.follow-up-detail {
    background: $gray-light;

    .breadcrumb {
        margin-top: rem-calc(15);
    }

    @include breakpoint(medium down) {
        .header.header-mobile {
            & .top-bar-left {
                display: none;
            }

            & .top-bar-center {
                width: calc(100% - 45px);
            }
        }

        .content__full {
            padding-top: 0;
        }
    }


    @include breakpoint(medium down) {
        .contact-infos {
            .panel_title {
                span {
                    display: inline;
                }

                & > span {
                    display: block;
                    float: left;
                    margin-right: rem-calc(30);
                    margin-top: rem-calc(2);
                    text-align: center;
                    width: auto;

                    i {
                        margin: 0;
                    }
                }

                & > h4 {
                    display: block;
                    margin: rem-calc(5 0);
                }
            }
        }
    }

    .request-user {
        &__car {
            background: $pampas;
            margin-bottom: rem-calc(15);
            padding: rem-calc(10 20);
            text-transform: uppercase;
        }

        &__phone {
            margin: rem-calc(10 0);

            @include breakpoint(medium down) {
                margin: 0;
            }
        }

        h4 {
            @include breakpoint(medium down) {
                font-size: rem-calc(16);
            }
        }
    }

    .request-left {
        .form-group {
            margin: rem-calc(0);
        }

        .row {
            margin-bottom: rem-calc(15);
        }

        .custom-checkbox {
            input {
                display: none;
            }
        }

        .accordion {
            &-title {
                background: $pampas;
                margin-top: rem-calc(15);
                padding: rem-calc(10 15);

                h4 {
                    color: $secondary-color;
                    font-size: rem-calc(18);
                    text-transform: uppercase;

                    i {
                        float: right;
                    }
                }

                &[aria-expanded="true"] {
                    i {
                        transform: rotate(180deg);
                    }
                }
            }

            &-content {
                background: $pampas;

                h5 {
                    font-size: rem-calc(14);
                }
            }
        }
    }

    .panel--help-argument {
        h3 {
            text-transform: uppercase;

            @include breakpoint(medium down) {
                color: $white;
                padding-bottom: rem-calc(10);

                i {
                    float: right;
                }
            }
        }

        .panel_content {
            @include breakpoint(medium down) {
                display: none;

                &.expanded {
                    display: block;
                }
            }
        }
    }

    .panel-show-more {

        &__button {
            color: $white;
            display: inline-block;
            font-family: $header-font-family;
            font-size: rem-calc(16);
            padding: rem-calc(0 0 15 0);
        }
    }

    .dr-form {
        &--part2 {
            text-align: center;
        }

        &--inner {
            display: inline-block;
            text-align: left;

            .form-label,
            .form-input {
                padding-left: 0;
                text-align: center;
                width: auto;
            }
        }
    }
}

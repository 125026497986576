/* PDF DOWNLOADS */

.modal-pdf__item_delegation{
    padding: rem-calc(15);
    text-align: left;

    &__item {
        display: inline-block;
        vertical-align: top;

        &--small {
            width: 30%;
        }

        &--picture {
            display: inline-block;
            margin: rem-calc(10);
            position: relative;
        }

        a {
            display: block;
        }

        img {
            width: 100%;
        }

        h4 {
            font-size: rem-calc(16);
            height: rem-calc(40);
            margin: rem-calc(5 0 10 0);
            max-width: rem-calc(240);
            text-transform: uppercase;
            transition: color 300ms ease-out;
        }

        &:hover {

            .modal-pdf__item--picture {
                border-color: $secondary-color;
            }

            h4 {
                color: $primary-color;
            }
        }

        &--hover {
            @include background-opacity($secondary-color, .8);
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            transition: opacity 300ms ease-out;
            width: 100%;

            &:hover {
                opacity: 1;
            }

            i {
                color: $white;
                font-size: rem-calc(50);
                margin: rem-calc(40);
            }
        }
    }

    &__close {
        font-family: $header-font-family;
        font-size: rem-calc(16);
        text-align: right;
        text-transform: uppercase;

        i {
            color: $primary-color;
            font-size: rem-calc(12);
            margin: rem-calc(-3px 0 0 5px);
        }
    }

}

.modal-pdf {
    padding: rem-calc(15);
    text-align: center;

    &__item {
        display: inline-block;
        vertical-align: top;

        &--small {
            width: 30%;
        }

        &--picture {
            display: inline-block;
            margin: rem-calc(10);
            position: relative;
        }

        a {
            display: block;
        }

        img {
            width: 100%;
        }

        h4 {
            font-size: rem-calc(16);
            height: rem-calc(40);
            margin: rem-calc(5 0 10 0);
            max-width: rem-calc(240);
            text-transform: uppercase;
            transition: color 300ms ease-out;
        }

        &:hover {

            .modal-pdf__item--picture {
                border-color: $secondary-color;
            }

            h4 {
                color: $primary-color;
            }
        }

        &--hover {
            @include background-opacity($secondary-color, .8);
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            transition: opacity 300ms ease-out;
            width: 100%;

            &:hover {
                opacity: 1;
            }

            i {
                color: $white;
                font-size: rem-calc(50);
                margin: rem-calc(40);
            }
        }
    }

    &__close {
        font-family: $header-font-family;
        font-size: rem-calc(16);
        text-align: right;
        text-transform: uppercase;

        i {
            color: $primary-color;
            font-size: rem-calc(12);
            margin: rem-calc(-3px 0 0 5px);
        }
    }
}

.close-button span {
    background: $white;
}

.modal-event,
.modal-sync {
    padding: rem-calc(15);
    text-align: center;

    h4 {
        margin-bottom: rem-calc(30);
        text-transform: uppercase;
    }
}

.modal-sync {
    a {
        text-decoration: underline;
    }
}


//
.modal_form_vente{
    .modal-form__close{
        text-align: right;
    }
    @include breakpoint(small down) {
        padding-top: 100px;
    }
}




.modal_save_fiche{
    .cta_fiche_save{
        width: 100%;
        display: flex;
        div:first-child{
            padding: 5px 0;
            order: 1;
        }
        div:last-child{
            padding: 5px 0;
            order: 2;
        }
        @include breakpoint(small down) {
         flex-direction: column;
            div:first-child{
                padding: 5px 0;
                order: 2;
            }
            div:last-child{
                padding: 5px 0;
                order: 1;
            }
            .modal-pdf__item{
                width: 100%;
                max-width: 200px;
                button{
                    width: 100%;
                }
            }
        }
    }

    .modal-pdf__item{
        #saveFicheRelance{
            background: #00368F;
            color: white;
            &:hover{
                background: #002c42;
            }
        }
    }
    @include breakpoint(small down) {
        padding-top: 100px;
    }
}


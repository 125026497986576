.login {
    background: url('../images/security/body-bg.jpg') no-repeat 50% 100%;
    background-size: cover;
    height: 100%;
    overflow: auto;

    h1 {
        font-size: rem-calc(60);
        text-transform: uppercase;

        @include breakpoint(medium down) {
            font-size: rem-calc(32);
        }
    }

    .wrapper > .container {
        @include breakpoint(small down) {
            height: calc(100vh - 60px);
            min-height: rem-calc(550);
            position: relative;
        }
    }

    &-panel {
        color: $white;
        display: block;
        margin: rem-calc(100) auto 0;
        text-align: center;
        width: rem-calc(450);

        @include breakpoint(small down) {
            width: 100%;
        }

        .reset-password & {
            width: rem-calc(600);

            @include breakpoint(small down) {
                margin: rem-calc(50) auto 0;
                width: 100%;
            }

            button {
                margin-top: rem-calc(30);
            }
        }

        label,
        button,
        .link-reset,
        input {
            display: block;
            margin: 0 auto;
            text-align: left;
            width: rem-calc(280);
        }

        input {
            background: $gray-medium;
            border: 1px solid $white;
        }

        label {
            color: $white;
            font-family: $header-font-family;
            font-size: rem-calc(14);
            margin-top: rem-calc(15);
            text-transform: uppercase;
        }

        button {
            background: $primary-color;
            font-family: $header-font-family;
            font-size: rem-calc(14);
            text-align: center;
        }

        .link-reset {
            margin-top: rem-calc(25);

            a {
                color: #fff;
            }
        }
    }

    .footer-label {
        bottom: rem-calc(15);
        color: $white;
        position: absolute;
        right: rem-calc(15);
    }

    .alert-danger {
        background: $white;
        border: 1px solid get-color(alert);
        color: get-color(alert);
        display: inline-block;
        font-weight: bold;
        padding: rem-calc(5 10);
        text-transform: uppercase;
    }

    .link-reset {
        text-align: right;

        a {
            text-decoration: underline;
        }
    }
}

.reset-password {
    .wrapper > .container {
        @include breakpoint(small down) {
            height: 100%;
            min-height: calc(100vh - 60px);
            position: relative;

            .login-panel {
                padding: rem-calc(10 10 50);
            }
        }
    }
}

@mixin badge-icon($color, $font-color, $size) {
    display: inline-block;
    position: relative;

    .badge-icon--icon {
        color: $color;
        font-size: rem-calc($size);
    }

    .badge-icon--label {
        color: $font-color;
        font-family: $body-font-family;
        font-size: rem-calc($size/2);
        font-weight: bold;
        height: 100%;
        left: 0;
        line-height: rem-calc($size*1.2);
        margin: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
    }
}

.badge-icon {
    @include badge-icon($primary-color, $gray-light, 22);
}

/* COMMON STYLE
/* *************************** */

.inline-block {
    display: inline-block;
}

.row .paste-blocks {
    .columns {
        padding: 0;
    }
}

.stats-details {
    .panel_cta {
        display: none;
    }

    table {
        font-family: $header-font-family;

        th {
            background: $gray;
            border: 1px solid $gray-light;
            color: $secondary-color;

            &.th-conversion {
                background: darken($gray, 7%);
            }
        }

        & > tbody > tr {
            color: $black;
            cursor: pointer;
            text-transform: uppercase;

            td {
                background: darken($gray, 5%);
                border: 1px solid $gray-light;
                overflow: hidden;
                padding: rem-calc(12);
                text-align: center;
                white-space: nowrap;
            }
        }

        .td-state-priority {
            td {
                background: darken($gray, 5%);
            }

            .td-conversion {
                background: darken($gray, 10%);
            }
        }

        .td-state-main {
            td {
                background: $bone;
                font-weight: normal;
            }

            .td-conversion {
                background: darken($gray, 1%);
            }
        }

        .td-state-global {
            td {
                background: darken($gray, 20%);
            }

            .td-conversion {
                background: darken($gray, 25%);
            }
        }
    }

    .filter-label {
        padding: .25em;
    }

    .filter-fields {
        padding-top: .25em;
        width: 100%;

        select {
            display: inline-block;
            height: 27px;
            margin-bottom: .5em;
            padding-bottom: 0;
            padding-top: 0;
            width: 31.5%;
        }

        select:nth-of-type(2) {
            margin-left: 2.5%;
        }

        select:nth-of-type(3) {
             margin-left: 2.5%;
         }

        .filter-period {
            display: inline-block;
            padding-left: 0;
            padding-right: 0;
            width: 85%;
        }

        .filter-errors {
            color: $alert-color;

            ul {
                list-style: none;
                margin-left: 0;
                text-align: left;
            }
        }
    }
}

.header-stats-test-drive {
    background-color: $gray-dark;

    .panel_title--main {
        color: $bleu;
    }

    .panel__stats {
        display: flex;
        flex-direction: column;
        height: 250px;
        margin-bottom: 1.25rem;
        padding-bottom: 1.66667rem;

        .stat-value {
            font-size: 55px;
        }

        .padding-left {
            padding-left: 10px;
        }
    }

    .white-bloc {
        background-color: $white;
        color: $prussian-blue;

        .panel_title--main,
        .panel__stats--cols {
            color: $prussian-blue;
        }
    }
}

.stat-value-small {
    font-size: rem-calc(20);
    font-weight: bold;
    line-height: 20px;
    text-align: center;
}

@media screen and (max-width: 1450px) {
    .stat-value-small {
        font-size: rem-calc(15);
    }
}

@media screen and (max-width: 1200px) {
    .stat-value-small {
        font-size: rem-calc(11);
    }
}

.stats-message {
    i {
        color: $white;
        font-size: 1.5rem;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        width: 2rem;
    }

    span {
        font-weight: bold;
    }
}

@media screen and (max-width: 1023px) {
    .hidden-xs {
        display: none;
    }

    .row.expand.col-xs {
        margin: 0;
        width: 50%;

        .filter-label {
            display: block;
            padding-bottom: 1rem;
            padding-top: 1.5rem;
            text-align: left;

            &.filter-period {
                padding-bottom: 4rem;
                padding-top: 3rem;
            }
        }
    }

    .panel_content.flex-xs {
        display: flex;
    }
}

@media screen and (max-width: 640px) {
    .row.expand.col-xs {
        .filter-label {
            margin-top: 1rem;
            padding-bottom: 0;
            padding-top: 0;

            &.filter-period {
                padding-bottom: 2rem;
                padding-top: 1rem;
            }
        }
    }
}

/* OPERATION DETAILS
/* *************************** */

.stats-campaign-details {
    &__header {
        img {
            margin: rem-calc(0 30 30 15);
            max-height: 200px;
            max-width: 200px;
        }

        p {
            margin: rem-calc(10 0);
        }
    }
}

.stats-priority-details {
    margin: rem-calc(10 0);

    &__flags,
    &__value,
    &__label {
        display: inline-block;
        vertical-align: middle;
    }

    &__flags {
        width: rem-calc(65);

        i {
            font-size: rem-calc(16);
        }
    }

    &__value {
        margin-right: rem-calc(15);
        text-align: right;
        width: rem-calc(65);
    }

    .flag-orange {
        color: $primary-color;
    }

    span {
        display: inline-block;
        white-space: nowrap;
    }
}

.title-block {
    background-color: $bone;
    color: $prussian-blue;
    font-weight: bold;
    margin: 0 auto;
    padding: 0 25px;
    width: 360px;
}

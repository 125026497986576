.homepage {
    .panel_content__bar-data {
        padding: 0;
    }

    .home-bar-data {
        display: block;
    }


    .panel-stats {
        font-family: $header-font-family;
        padding: rem-calc(15 30);

        h5 {
            background: $pampas;
            display: table;
            height: rem-calc(60);
            margin-bottom: rem-calc(10);
            text-align: center;
            text-transform: uppercase;
            width: 100%;

            span {
                display: table-cell;
                vertical-align: middle;
            }
        }

        &__data {
            font-size: rem-calc(40);
        }

        &__label {
            margin-top: rem-calc(6);
            text-transform: uppercase;
        }
    }

    .panel.news {
        max-height: calc(100vh - 200px);
        overflow: auto;

        .news-date {
            color: $gray-dark;
            font-family: $header-font-family;
            font-size: rem-calc(14);
            margin: rem-calc(5 0 15);
            text-transform: uppercase;
        }

        .news-content {
            font-size: rem-calc(11);


            a {
                text-decoration: underline;
            }
        }
    }
}

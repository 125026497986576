.button {
    font-family: $header-font-family;

    &.white {
        background: $white;
        color: $secondary-color;
    }

    &.orange {
        background-color: $white;
        color: $primary-color;
    }

    &.orangedeux {
        background-color: $primary-color;
        color: $white;
    }

    &.big {
        padding: rem-calc(15);
    }

    &.large {
        width: 100%;
    }
}

// sass-lint:disable-block no-qualifying-elements force-attribute-nesting
.sr-table__footer a[data-toggle="print-modal"],
body .form-group__submit .btn-print {
    @include breakpoint(small down) {
        display: none;
    }
}
